<template>
  <b-container class="bg-light page-wrapper sandbox-create-tink-connection">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col> </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Create Tink Connection demo</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="12">
          <create-tink-connection @success="onSuccess" @fail="onFail"/>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h4 class="mt-5">Emitted data:</h4>
          <pre>{{ emittedData }}</pre>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import CreateTinkConnection from '@/components/common/CreateTinkConnection.vue'
export default {
  name: 'CreateTinkConnectionExample',
  components: { CreateTinkConnection },
  data () {
    return {
      emittedData: null
    }
  },
  methods: {
    onSuccess (data) {
      this.emittedData = data
    },
    onFail () {
      this.emittedData = null
    }
  }
}
</script>
